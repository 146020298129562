import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import SiteHeader from './SiteHeader'
import device from '../device.js'

const PageHeader = ({ className, heading, introduction, backgroundImage }) => (
  <div className={className} style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : '' }}>
    <SiteHeader darkMode/>
    <Container>
      <h1>{heading}</h1>
      <div dangerouslySetInnerHTML={{ __html: introduction  }} />
    </Container>
  </div>
)

export default styled(PageHeader)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  pointer-events: none;
  padding-bottom: 120px;

  ${({ backgroundImage }) => backgroundImage && `
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-bottom: 120px;
      height: 100%;
      background-image: linear-gradient(143deg, var(--blue-600) 20%, var(--blue-500) 100%);
      pointer-events: none;
      opacity: 0.7;
      
      @supports (mix-blend-mode: multiply) {
        opacity: 1;
        mix-blend-mode: multiply;
      }
    }
  `}

  ${({ backgroundImage }) => !backgroundImage && `
    background: var(--blue-700);
    background: linear-gradient(143deg, var(--blue-600) 20%, var(--blue-500) 100%);
  `}

  ${SiteHeader} {
    position: relative;
    z-index: 101;
    pointer-events: auto;
  }

  h1, h2, h3, h4, h5, p {
    color: var(--white);
    max-width: 700px;
    position: relative;
  }

  h1 {
    font-size: 30px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    position: relative;

    border-bottom: 4px solid var(--red);
    display: inline-block;

    @media ${device.mobileL} {
      font-size: 40px;
    }

    @media ${device.tablet} {
      font-size: 50px;
      padding-bottom: 60px;
      margin-bottom: 60px;
    }
  }

  p {
    font-size: 20px;
    margin-top: 0;
    line-height: 1.5;

    @media ${device.mobileL} {
      font-size: 24px;
    }

    @media ${device.tablet} {
      font-size: 30px;
    }
  }
`;