import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import device from '../device'

const externalCheck = (link) => link.indexOf('.') !== -1

const Button = ({ 
  className, label, type, disabled, loading, loadingLabel, 
  linkTo, onClick
}) => linkTo ? (
  externalCheck(linkTo) ? 
  <a className={className} href={linkTo}>
    {label}
  </a> :
  <Link className={className} to={linkTo}>
    {label}
  </Link>
) : (
  <button 
    type={type}
    className={className}
    disabled={disabled || loading}
  >
    {(loading && loadingLabel) || label}
  </button>
)

export default styled(Button)`
  color: var(--blue-700);
  background: ${({ disabled }) => disabled ? 'var(--blue-100)' : 'transparent'};
  display: inline-block;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border: 1px solid var(--blue-700);
  border-radius: 5px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  max-width: 100%;

  &:hover {
    background: ${({ disabled }) => disabled ? 'var(--blue-100)' : 'var(--blue-700)'};
    color: ${({ disabled }) => disabled ? 'var(--blue-700)' : 'var(--white)'};
  }

  font-size: 14px;
  padding: 14px;

  @media ${device.mobileM} {
    font-size: 16px;
    padding: 16px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    padding: 18px;
  }
`