import React from 'react'
import styled from 'styled-components'

const Panel = ({ className, children }) => (
  <div className={className}>
    {children}
  </div>
)

export default styled(Panel)`
  margin: 80px 0;
  border-radius: 10px;
  box-shadow: 0px 2px 15px 1px var(--blue-shadow);
  overflow: hidden;
  background: var(--white);
`