import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import device from '../device.js'
import Button from './Button'

const FeaturedProducts = ({ className, featuredHeading, products }) => (
  <div className={className}>
    <Container>
      {/* <h2>{featuredHeading}</h2> */}
      <ul>
        {products && products.map(({ image, heading, description, link_label, link }) => (
          <li key={heading.text}>
            <img src={image.url} alt={heading.text} />
            <div className="content">
              <div>
              <h3>{heading.text}</h3>
              <div dangerouslySetInnerHTML={{ __html: description.html }} />
              </div>
              <Button 
              label={link_label.text}
              linkTo={link.url}
              />
            </div>
          </li>
        ))}
      </ul>
    </Container>
  </div>
)

export default styled(FeaturedProducts)`
background: var(--blue-200);
background-image: linear-gradient(0deg, var(--blue-200) 20%, var(--blue-300) 100%);
padding: 10px;
z-index: 100;
position: relative;

ul {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-bottom: 40px;
  margin-top: -100px;

  @media ${device.tablet} {
    display: flex;
  }

  li {
    background: var(--white);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px var(--blue-shadow-dark);
    margin: 20px 0 40px;

    @media ${device.tablet} {
      margin: 0 5px;
      // max-width: 320px;

      :first-child {
        margin-left: 0;
      }

      :last-child {
        margin-right: 0;
      }
    }

    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .content {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1;

      h3 {
        color: var(--blue-600);
        font-size: 20px;
        margin-bottom: 30px;
      }

      p {
        color: var(--gray-500);
        font-size: 16px;
        margin-bottom: 20px;
      }

      ${Button} {
        font-size: 14px;
        font-weight: 500;
        background: 0;
        border: 1px solid var(--blue-600);
        color: var(--blue-600);
        display: block;
        justify-self: flex-end;
        transition: background 100ms linear;
        margin-top: 40px;

        &:hover {
          background: var(--blue-200);
          transition: background 250ms linear;
        }
      }
    }
  }
}
`;

      