import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import device from '../device.js'

const Block = styled.div`
  padding: 80px 40px;
  text-align: center;
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :nth-child(even) {
    background: var(--blue-700);
  }

  h4 {
    color: var(--white);
    font-weight: 500;
    font-size: 24px;
    max-width: ${({ large }) => large ? '700px' : '400px'};
    margin: 0;

    @media ${device.mobileL} {
      font-size: ${({ large }) => large ? '36px' : '24px'};
    }
  }

  p {
    color: var(--blue-200);
    margin-bottom: 0;
    max-width: ${({ large }) => large ? '700px' : '400px'};
  }

  ${Button} {
    color: var(--white);
    font-weight: 500;
    background: 0;
    border: 1px solid var(--blue-400);
    transition: background 100ms linear;
    margin-top: 40px;

    &:hover {
      background: rgba(256,256,256, 0.1);
      transition: background 250ms linear;
    }
  }
`

const NextSteps = ({ className, steps }) => (
  <div className={className}>
    {steps.map(({ heading, message, button_label, link }) => (
      <Block large={steps.length < 2} key={heading.text}>
        {heading && <h4>{heading.text}</h4>}
        {message && <p>{message.text}</p>}
        <Button
          label={button_label.text}
          linkTo={link.url}
        />
      </Block>
    ))}
  </div>
)

export default styled(NextSteps)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: var(--blue-800);
`
