import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"
// import Link from 'gatsby-link'

const LogoBanner = ({ className }) => (
  <StaticQuery
    query={graphql`
      query LogoBannerQuery{
        prismicLogoBanner {
          id
          data {
            heading {
              text
            }
            logos {
              image {
                url
              }
              label {
                text
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { logos } = data.prismicLogoBanner.data
      return (
      <div className={className}>
      <h4>{data.prismicLogoBanner.data.heading.text}</h4>
        <ul>
          {logos && logos.map(({ image, label }) => (
            <li key={label.text}>
              <img src={image.url} alt={label.text} />
            </li>
          ))}
        </ul>
      </div>
    )}}
  />
)

export default styled(LogoBanner)`
  padding: 40px 0;
  text-align: center;

  h4 {
    font-size: 18px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 20px 0;

    li { 
      margin: 20px 30px;
      flex: 1 1 120px;
      max-width: 120px;

      img {
        width: 100%;
      }
    }
  }
`