import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import Panel from './Panel'
import device from '../device'

const ProductList = ({ className, listHeading, products }) => (
  <Container className={className}>
    <Panel>
      <h2>{listHeading}</h2>
      <ul>
        {products && products.map(({ heading, description, link_label, link, image }) => (
          <li key={heading.text}>
            {image && <img src={image.url} alt="" />}
            <div className="content">
              <h3>{heading.text}</h3>
              <div dangerouslySetInnerHTML={{ __html: description.html }} />
              {link && <a href={link.url}>{link_label.text}</a>}
            </div> 
          </li>
        ))}
      </ul>
    </Panel>
  </Container>
)

export default styled(ProductList)`
  ${Panel} {
    margin: 80px 0;
    padding: 20px 40px;
  
    h2 {
      font-size: 28px;
      border-bottom: 2px solid var(--red);
      display: inline-block;
      padding: 0 0 20px;
    }
  
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  
    li {
      margin: 20px 0;
      padding: 0;
      border-bottom: solid 1px var(--blue-200);

      @media ${device.tablet} {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
      }

      :last-child {
        border: 0;
      }

      img {
        width: 200px;
      }

      .content {
        margin-top: 40px;
        padding: 0px 0px 40px;
      
        @media ${device.tablet} {
          margin-top: 0;
          padding: 40px 60px 60px;
        }

        h3 {
          margin-top: 0;
        }
    
        p {
          font-size: 16px;
          max-width: 700px;
        }
      }
    }
  }
`;

      